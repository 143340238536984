// Import the functions you need from the SDKs you need

import { Auth, getAuth } from "firebase/auth";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC9fH_cJSLV7t4fFIjenbPRoh248tUglao",
  authDomain: "jylep-todo-app.firebaseapp.com",
  projectId: "jylep-todo-app",
  storageBucket: "jylep-todo-app.appspot.com",
  messagingSenderId: "277120809450",
  appId: "1:277120809450:web:db63af57f1039d66d25a93"
};

/**
 * Singleton class representing the firebase project 
 */
export class FirebaseState {
  private _firebaseState?: FirebaseState;
  private _app?: FirebaseApp;
  private _auth?: Auth;
  private _db?: Firestore;
  private _configuration: FirebaseOptions;

  constructor(configuration: FirebaseOptions = firebaseConfig) {
    this._configuration = configuration;
    if (this._firebaseState) { return this._firebaseState; }
    else {
      this._firebaseState = this;
    }
  }
  
  /**
   * Returns a singleton instance of a FirebaseApp
   */
  public get fApp() : FirebaseApp {
    if (this._app) { return this._app; }
    else {
      this._app = initializeApp(this._configuration);
      return this._app;
    }
  }

  /**
   * Returns a singleton instance of Firebase Auth
   */
  public get fAuth() : Auth {
    if (this._auth) { return this._auth; }
    else {
      this._auth = getAuth(this.fApp);
      return this._auth;
    }
  }

  /**
   * Returns a singleton instance of Firestore
   */
  public get fDb() : Firestore {
    if (this._db) { return this._db; }
    else {
      this._db = getFirestore(this.fApp);
      return this._db;
    }
  }
}