import React, { useState } from 'react';

import { Task } from '../models';
import { useFirebaseAuth } from '../hooks/useFirebaseAuth';

// import { Task } from '@models';


export const TaskCreateForm: React.FC<{ onFormSubmit: (task: Task) => void }> = ({ onFormSubmit }) => {

  const [taskDescription, setTaskDescription] = useState<Task["description"]>("");
  const user = useFirebaseAuth();

  // Whenever we add a new task, save it
  const onSubmit = (e: React.FormEvent): void => {
    // Prevent html form submission
    e.preventDefault();

    // No need to do anything if no description provided
    if (!taskDescription || !user) { return; }

    const newTask: Task = new Task(taskDescription, user.uid);
    setTaskDescription("");
    onFormSubmit(newTask);
  }

  return (
    <form className="tasks__form" onSubmit={(e) => onSubmit(e)}>
      <input
        className="tasks__form_field"
        data-testid="task_create_input"
        value={taskDescription}
        placeholder="Something you'd like to plan?"
        onChange={(e) => setTaskDescription(e.target.value)}
        type="text"
        name="task"
        id="task"
      />
      <button className="tasks__form_btn pointer uppercase" type="submit">create</button>
    </form>
  )
}

export default TaskCreateForm;