import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

import { FirebaseState } from 'src/firebase';
import React from 'react';
import { useFirebaseAuth } from '../hooks/useFirebaseAuth';

export const Header: React.FC = () => {

  const firebaseState = new FirebaseState();
  const auth = firebaseState.fAuth;
  const user = useFirebaseAuth();

  const onAuthClicked = () => {
    user ? signOut(auth) : signInWithPopup(auth, new GoogleAuthProvider());
  }

  return (<header style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <button onClick={() => onAuthClicked()}> { user ? "Log Out" : "Log In" }</button>
    <figure className='logo'></figure>
    <h1 className='uppercase'>Task tracker</h1>
  </header>)
}

export default Header;