import React, { useEffect, useState } from 'react';
import { Task as TaskModel, TaskStatus, TaskWithDocId } from '@models';
import { addDoc, collection, deleteDoc, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';

import { FirebaseState } from '../firebase';
import Task from './Task';
import TaskCreateForm from './TaskCreateForm';
import { User } from 'firebase/auth';
import { useFirebaseAuth } from '../hooks/useFirebaseAuth';

export const Tasks: React.FC = () => {

  const firebaseState = new FirebaseState();
  const db = firebaseState.fDb;
  const user = useFirebaseAuth(firebaseState);
  const [tasks, setTasks] = useState<TaskWithDocId[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  // Fetch tasks and listen to changes
  useEffect(() => {
    const fetchTasks = (user: User | null) => {
      setFetching(true);
      let queryRef = user !== null
        ? query(collection(db, "todos"), where("userId", "==", user.uid))
        : query(collection(db, "todos"));
      return onSnapshot(queryRef,
        (taskSnapshots) => {
          // Received updates
          const receivedTasks = taskSnapshots.docs.map(
            qdsTodo => {
              const docData = qdsTodo.data() as TaskModel;
              return { id: qdsTodo.id, ...docData };
            }
          );
          receivedTasks.sort((t1, t2) => {
            if (t1.status === t2.status) {
              // sort by id (time created here)
              return 0;
            }
            // sort by status
            return t1.status === "COMPLETED" ? 1 : -1;
          });
          setFetching(false);
          setTasks([...receivedTasks]);
        },
        (firestoreErr) => {
          // Error while fetching
          console.error(firestoreErr);
        }
      );
    }
    const unsubscribe = fetchTasks(user);
    return () => unsubscribe();
  }, [user, db])

  const onCreated = async (newTask: TaskModel): Promise<void> => {
    try {
      const { editing, ...task } = newTask;
      await addDoc(collection(db, "todos"), { ...task });
    } catch (error) {
      console.error(error);
    }
  }
  const onDeleted = async (id: TaskWithDocId["id"]): Promise<void> => {
    try {
      await deleteDoc(doc(db, "todos", id));
    } catch (err) {
      console.error(err);
    }
  }
  const onUpdated = async (id: TaskWithDocId["id"], updatedTask: TaskModel): Promise<void> => {
    try {
      const { editing, ...task } = updatedTask;
      await updateDoc(doc(db, "todos", id), { ...task });
    } catch (err) {
      console.error(err);
    }
  }
  const editingToggled = (id: TaskWithDocId["id"]): void => {
    setTasks([...tasks.map(t => ({ ...t, editing: t.id === id ? !t.editing : t.editing }))]);
  }
  const onStateToggled = async (id: TaskWithDocId["id"]): Promise<void> => {
    try {
      const task = tasks.find(t => t.id === id);
      const newStatus: TaskStatus = task?.status === "COMPLETED" ? "TODO" : "COMPLETED";
      await updateDoc(doc(db, "todos", id), { status: newStatus });
    } catch (err) {
      console.error(err);
    }
  }
  const renderTasks = () => {
    return tasks.length < 1
      ? "Lucky you! There is nothing that requires your attention, you can relax and take a breather!"
      : <ul>
          {
            tasks.map((t) => (<Task
              key={t.id}
              task={t}
              stateToggled={onStateToggled}
              deleted={onDeleted}
              updated={onUpdated}
              editingToggled={editingToggled} />))
          }
        </ul>
  }

  return (<section className="tasks">
    <TaskCreateForm onFormSubmit={onCreated} />

    <div className={`tasks__list ${tasks.length === 0 ? 'tasks__list-empty' : ''}`}>
      { fetching === true
        ? "Fetching..."
        : renderTasks()
      }
    </div>
  </section>)
}

export default Tasks;