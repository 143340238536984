import { Timestamp } from "firebase/firestore"
import { WithDocId } from '../utils';

export type TaskStatus = "DONE" | "IN-PROGRESS" | "PAUSED" | "COMPLETED" | "TODO";
export class Task {
  description: string;
  status: TaskStatus;
  createdAt: Timestamp | Date;
  startedAt: null | Timestamp | Date;
  completedAt: null | Timestamp | Date;
  editing: boolean;
  userId: string;

  constructor(description: string, userId: string) {
    this.description = description;
    this.status = "TODO";
    this.createdAt = new Date();
    this.startedAt = null;
    this.completedAt = null;
    this.editing = false;
    this.userId = userId;
  }
}

export type TaskWithDocId = WithDocId<Task>;