import { User, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";

import { FirebaseState } from "src/firebase";

export const useFirebaseAuth = function(firebaseState: FirebaseState = new FirebaseState()) {

  const auth = firebaseState.fAuth;
  const [authState, setAuthState] = useState<User | null>(null);
  const onAuthNext = (user: User | null) => {
    setAuthState(user);
  };
  const onAuthError = (error: Error) => {
    console.error("An error occured while listening to auth state", error);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, onAuthNext, onAuthError);
    return () => unsubscribe();
  }, [authState, auth])

  return authState;
}